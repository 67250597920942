<template>
  <div>
    <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" :title="uploadFilesTitle" center
      width="810px" :visible="uploadFilesVisible" @close="uploadFilesDialogClose">
      <p class="fs6 mb10">请先下载导入模板的模板文件，按照文件内的内容编辑后再"选择文件上传"即可批量导入。</p>
      <el-checkbox v-if="showDelOldFile" v-model="isDelData" class="mb10 elCheckbox">导入并删除旧数据</el-checkbox>
      <div>导入模板：<a class="cblue ml20" href="#" @click="downloadModel">点击下载</a></div>

      <div class="posre mt20 mb20">
        <!-- <el-upload class="mt20 mb20 upload-demo" ref="upload" list-type="picture" action="#" accept=".xls,.xlsx"
          :auto-upload="false" :show-file-list="false" :on-change="handleUpload" :on-remove="handleRemove"
          :file-list="fileList">
          <el-button class="w250 text-overflow" size="small" slot="trigger" type="primary">{{ fileName }}</el-button>
        </el-upload> -->
        <!-- <el-radio-group v-model="radio1">
          <el-radio-button label="6">完整表导入</el-radio-button>
          <el-radio-button label="0">简易表导入</el-radio-button>
        </el-radio-group> -->
        <div class="w100p mb20" @click="importNumberFun">
          <el-upload class="upload-demo w100p importModel" ref="upload" action="#" multiple accept=".xls,.xlsx"
            :auto-upload="false" :on-change="handleUpload" :on-remove="handleRemove" :file-list="fileList" drag
            :show-file-list="false">
            <i v-if="addTableDataLoading" class="el-icon-loading"></i>
            <i v-else class="el-icon-upload"></i>
            <div class="el-upload__text" v-if="fileName">
              将文件拖到此处，或 <em>点击上传</em>
              <div>
                只能上传xlsx格式文件
              </div>
            </div>
            <div v-else>
              已导入该表格内容{{ fileName }}
            </div>
          </el-upload>
        </div>
        <div>
          <!-- <div v-for="(row, index) of errorUrl" :key="index">
            <a class="ml20 cred" href="#" @click="downloadError(row.url)">{{ row.name }} 错误!</a>
          </div> -->
          <table border="1" cellspacing="0" class="tableBorder w100p">
            <thead>
              <tr class="h40 baf7fafa">
                <td class="textc itemTitle w300">文件名称</td>
                <td class="textc itemTitle">提示</td>
                <td class="textc itemTitle">操作</td>
              </tr>
            </thead>
            <tbody v-if="JSON.stringify(formDataValue) != '{}'">
              <tr class="h40" v-for="(row, index) in formDataValue" :key="index">
                <td class="itemContent pl10 pr10">
                  {{ row.fileName }}
                </td>
                <td class="itemContent e6a23c pl10 pr10">
                  <div v-html="row.message"></div>
                </td>
                <td class="textc itemContent w80">
                  <a v-if="row.errUrl" @click="downloadError(row.errUrl)" class="corange curPointer"
                    title="点击下载">错误文件</a>
                  <span v-else>-</span>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr class="h40">
                <td :colspan="3" class="itemContent textc">暂无导入文件</td>
              </tr>
            </tbody>
          </table>
          <el-button class="mt10" type="primary" :disabled="JSON.stringify(formDataValue) == '{}'" @click="uploadFileFun" size="mini">立即上传</el-button>
        </div>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: "commonTable",
  props: {
    showDelOldFile: { //是否显示删除久数据
      type: Boolean,
      default: false
    },
    showErrFile: { //是否显示错误文件
      type: Boolean,
      default: false
    },
    uploadFilesTitle: { //弹出框标题
      type: String,
      default: ''
    },
    uploadFilesVisible: { // 弹出框显隐
      type: Boolean,
      default: false
    },
    downloadModelUrl: {//下载模板url
      type: String,
      require: true
    },
    downloadModelType: { //弹出框标题
      type: String,
      default: 'post'
    },
    downloadModelFileName: {
      type: String,
      default: "项目模板"
    },
    uploadModelUrl: {//导入模板url
      type: String,
      require: true
    },
    data: {//上传数据
      type: Object
    },
    errorDownloadUrl: {
      type: String,
    }
  },
  data() {
    return {
      chooseCardId: null,
      uploadUrl: this.$store.state.uploadingUrl,
      userInfo: this.$store.state.loginRoot.userInfo,
      fileList: [],
      uploadFile: "",
      fileName: "点击上传",
      addTableDataLoading: false,
      isDelData: false,
      importNumber: 0,
      formDataValue: {},
      files: [],
    };
  },
  methods: {
    async uploadFileFun() {
      this.$message.info("导入中，请稍后...");
      this.addTableDataLoading = true;
      if (this.showDelOldFile) {
        for (let key in this.formDataValue) {
          let row = this.formDataValue[key];
          row.message = "<span class='c999'>上传中...</span>";
          this.$set(this.formDataValue, key, row);
        }
        let formData = new FormData();
        for (let k in this.data) {
          formData.append(k, this.data[k]);
        }
        formData.append('isDelData', this.isDelData);
        formData.append('companyId', this.userInfo.companyId);
        for (let i in this.files) {
          formData.append('file', this.files[i]);
        }
        let res = await axios({
          headers: {
            "Content-Type": "multipart/form-data"
          },
          method: 'post',
          url: '/api/crmPc/' + this.uploadModelUrl,
          data: formData
        })
        if (typeof res.data == 'object') {
          let i = 0;
          for (let key in this.formDataValue) {
            let row = this.formDataValue[key];
            if (res.data[i].code === 200) {
              row.message = "<span class='cblue'>" + res.data[i].message + "</span>";
            } else if (res.data[i].code == 202 && res.data && this.errorDownloadUrl) {
              row.errUrl = res.data[i].data;
              row.message = "<span class='corange'>" + res.data[i].message + "</span>";
            } else {
              row.message = "<span class='corange'>" + res.data[i].message + "</span>";
            }
            i++;
            this.$set(this.formDataValue, key, row);
          }
        } else if (typeof res.data == 'string') {
          for (let key in this.formDataValue) {
            let row = this.formDataValue[key];
            if (res.code === 200) {
              row.message = "<span class='cblue'>导入成功</span>";
            } else if (res.code == 202 && res.data && this.errorDownloadUrl) {
              row.message = "<span class='corange'>" + res.data + "</span>";
            } else {
              row.message = "<span class='corange'>" + res.data + "</span>";
            }
            this.$set(this.formDataValue, key, row);
          }
        } else {
          for (let key in this.formDataValue) {
            let row = this.formDataValue[key];
            row.message = "<span class='corange'>错误</span>";
            this.$set(this.formDataValue, key, row);
          }
        }
        this.addTableDataLoading = false;
      } else {
        for (let key in this.formDataValue) {
          let row = this.formDataValue[key];
          row.message = "<span class='c999'>上传中...</span>";
          let res = await axios({
            headers: {
              "Content-Type": "multipart/form-data"
            },
            method: 'post',
            url: '/api/crmPc/' + this.uploadModelUrl,
            data: row.data
          })
          if (res.code === 200) {
            row.message = "<span class='cblue'>" + res.message + "</span>";
          } else if (res.code == 202 && res.data && this.errorDownloadUrl) {
            row.errUrl = res.data;
            row.message = "<span class='corange'>" + res.message + "</span>";
            this.addTableDataLoading = false;
          } else {
            row.message = "<span class='corange'>" + res.message + "</span>";
            this.addTableDataLoading = false;
          }
          this.$set(this.formDataValue, key, row);
        }
      }
      this.addTableDataLoading = false;
      this.$emit('UploadFilesSuccess')
      // axios({
      //   headers: {
      //     "Content-Type": "multipart/form-data"
      //   },
      //   method: 'post',
      //   url: '/api/crmPc/' + this.uploadModelUrl,
      //   data: this.formDataValue[this.fileName].data
      // }).then(res => {
      //   this.formDataValue[this.fileName].message = res.message;
      //   if (res.code === 200) {
      //     this.$message.success(res.data)
      //     let params = {
      //       ...this.formDataValue
      //     }
      //     delete params[this.fileName]
      //     let promises = [];
      //     if (JSON.stringify(params) != '{}') {
      //       for (let key in this.formDataValue) {
      //         let row = this.formDataValue[key];
      //         promises.push(new Promise(open => {
      //           axios({
      //             headers: {
      //               "Content-Type": "multipart/form-data"
      //             },
      //             method: 'post',
      //             url: '/api/crmPc/' + this.uploadModelUrl,
      //             data: row.data
      //           }).then(result => {
      //             row.message = res.message;
      //             if (result.code == 200) {
      //               this.$message.success(result.data)
      //             } else if (result.code == 202 && result.data && this.errorDownloadUrl) {
      //               // this.$message.error(row.name + " 导入失败，" + result.message)
      //               // window.open('/api/crmPc/' +this.errorDownloadUrl+res.data);
      //               row.errUrl = result.data;
      //             } else {
      //               this.$message.error(row.name + " 导入失败，" + result.message)
      //             }
      //             open();
      //           })
      //         }))
      //       }else{
      // this.$emit('UploadFilesSuccess')
      // }
      //       Promise.all(promises).then(() => {
      //         this.$emit('UploadFilesSuccess')
      //         this.addTableDataLoading = false;
      //       })
      //     } else {
      //       this.addTableDataLoading = false;
      //     }
      //   } else if (res.code == 202 && res.data && this.errorDownloadUrl) {
      //     // this.$message.error(this.formDataValue[0].name + " 导入失败，" + res.message);
      //     this.formDataValue[this.fileName].errUrl = res.data;
      //     this.addTableDataLoading = false;
      //   } else {
      //     this.$message.error(this.formDataValue[this.fileName].name + " 导入失败，" + res.message)
      //     this.addTableDataLoading = false;
      //   }
      // })

    },
    importNumberFun() {
      this.formDataValue = {};
      this.fileList = [];
      this.importNumber = 0;
      this.files = [];
    },
    downloadModel() {
      this.$message({
        message: "下载模板中...",
        type: "info"
      })
      if (this.downloadModelType == 'post') {
        axios.post('/api/crmPc/' + this.downloadModelUrl, {}, { responseType: 'blob' }).then(res => {
          let link = document.createElement('a');
          let url = window.URL.createObjectURL(res);
          link.href = url;
          link.download = this.downloadModelFileName + '.xls';

          link.click();
          window.URL.revokeObjectURL
        });
      } else {
        let link = document.createElement('a');
        link.href = '/api/crmPc/' + this.downloadModelUrl;
        link.click();
      }
    },

    //弹框页面切换
    uploadFilesDialogClose(val) {
      this.importNumberFun();
      this.$emit('uploadFilesDialogClose', val);
    },
    handleUpload(file) {
      this.uploadFile = file.raw
      this.fileName = file.name;
      this.onConfirmUploadFiles();
    },
    handleRemove() {

    },
    async onConfirmUploadFiles() {
      if (!this.uploadFile) {
        this.$message.error("请上传表格文件");
        return;
      }
      const fromData = new FormData()
      fromData.append('file', this.uploadFile);
      this.files.push(this.uploadFile);
      fromData.append('companyId', this.userInfo.companyId);
      for (let key in this.data) {
        fromData.append(key, this.data[key])
      }
      this.$set(this.formDataValue,this.fileName,{ fileName: this.fileName, data: fromData, message: "<span class='c999'>等待上传</span>", errUrl: "" });
      // this.formDataValue[this.fileName] = { fileName: this.fileName, data: fromData, message: "<span class='c999'>等待上传</span>", errUrl: "" };
      // this.$message.info("导入中，请稍后...");
      // this.addTableDataLoading = true;
      // let fileName = this.fileName;
      // const result = await axios({
      //   headers: {
      //     "Content-Type": "multipart/form-data"
      //   },
      //   method: 'post',
      //   url: '/api/crmPc/' + this.uploadModelUrl,
      //   data: fromData
      // })
      // this.formDataValue[fileName].message=result.message;
      // if (result.code == 200) {
      //   this.$message.success(result.data)
      //   this.$emit('UploadFilesSuccess')
      //   this.addTableDataLoading = false;
      // } else if (result.code == 202 && result.data && this.errorDownloadUrl) {
      //   this.$message.error("导入失败，" + result.message)
      //   this.formDataValue[fileName].errUrl = result.data
      //   this.addTableDataLoading = false;
      // } else {
      //   this.$message.error("导入失败，" + result.message)
      //   this.addTableDataLoading = false;
      // }
      // this.$emit('onConfirmUploadFiles');


    },
    downloadError(url) {
      this.$message({
        message: "下载错误模板中...",
        type: "info"
      })
      let link = document.createElement('a');
      link.href = '/api/crmPc/' + this.errorDownloadUrl + url;
      link.click();
    },
  },
};
</script>

<style lang="scss">
.elCheckbox {
  color: red;

  .el-checkbox__input.is-checked+.el-checkbox__label {
    color: red;
  }

  .el-checkbox__input.is-checked .el-checkbox__inner {
    border-color: red;
    background-color: red;
  }
}
</style>

<style lang="scss" scoped>
.importModel {
  /deep/.el-upload-dragger {
    width: 100%;
  }

  /deep/.el-upload {
    width: 100%;
  }
}

.el-icon-loading {
  font-size: 67px;
  color: #c0c4cc;
  margin: 40px 0 16px;
  line-height: 50px;
}

// /deep/ .el-upload--text {
//   text-align: left;
//   height: 36px;
//   border: 0px;
// }</style>